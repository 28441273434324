/* General Styles */
#support-content body {
	font-family: Arial, sans-serif; /* Clean and formal font */
	line-height: 1.6; /* Improved readability */
	margin: 0;
	padding: 20px;
	background-color: #f9f9f9; /* Light background for contrast */
}

/* Headings */
#support-content h1 {
	font-size: 2.25rem; /* Reduced from 2.5rem to mimic Tailwind's h1 */
	font-weight: bold;
	margin-bottom: 1rem;
	color: #333; /* Darker color for emphasis */
}

#support-content h2 {
	font-size: 1.875rem; /* Reduced from 2rem to mimic Tailwind's h2 */
	font-weight: bold;
	margin-top: 1.5rem;
	margin-bottom: 0.75rem;
	color: #444;
}

#support-content h3 {
	font-size: 1.625rem; /* Reduced from 1.75rem to mimic Tailwind's h3 */
	/* font-weight: bold; */
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #555;
}

#support-content h4 {
	font-size: 1.375rem; /* Reduced from 1.5rem to mimic Tailwind's h4 */
	font-weight: bold;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

#support-content h5 {
	font-size: 1.125rem; /* Reduced from 1.25rem to mimic Tailwind's h5 */
	font-weight: normal;
}

#support-content h6 {
	font-size: 0.875rem; /* Reduced from 1rem to mimic Tailwind's h6 */
	font-weight: normal;
}

/* Paragraphs */
#support-content p {
	margin-bottom: 1rem; /* Space between paragraphs */
	color: #666; /* Subtle text color for body text */
}

/* Links */
#support-content a {
	color: #007bff; /* Bootstrap primary link color */
	text-decoration: none; /* Remove underline by default */
}

#support-content a:hover {
	text-decoration: underline; /* Underline on hover for clarity */
}

/* Images */
#support-content img {
	max-width: 100%; /* Responsive images */
	height: auto; /* Maintain aspect ratio */
	display: block; /* Remove inline spacing issues */
	margin: 1rem auto; /* Space around images */
	border: 2px solid rgba(0, 0, 0, 0.1); /* Subtle border around images */
	border-radius: 4px; /* Slightly rounded corners for a softer look */
}

/* Code Blocks */
#support-content code {
	background-color: #f0f0f0; /* Light gray background for inline code */
	border-radius: 4px; /* Rounded corners for code elements */
	padding: 2px 4px; /* Padding around inline code */
}

#support-content pre {
	background-color: #f0f0f0; /* Light gray background for code blocks */
	border-left: 4px solid #007bff; /* Left border for emphasis */
	padding: 10px; /* Padding inside the code block */
	overflow-x: auto; /* Horizontal scroll if needed */
}

/* Tables */
#support-content table {
	width: 100%; /* Full width tables */
	border-collapse: collapse; /* Collapse borders for cleaner look */
}

#support-content th,
#support-content td {
	border: 1px solid #ddd; /* Light gray border for table cells */
	padding: 8px; /* Padding inside cells for spacing */
}

#support-content th {
	background-color: #a8afb5; /* Gray color for table headers */
	color: white; /* White text on header background */
}

#support-content th > p {
	color: white; /* White text on header background */
}

#support-content tr:nth-child(even) {
	background-color: #f2f2f2; /* Zebra striping effect for rows */
}

#support-content tr:hover {
	background-color: #e9ecef; /* Highlight row on hover for clarity */
}
